import swal from 'sweetalert2';
import Select from 'react-select';
import { useEffect, useState } from "react";
import * as helper from '../global/helper';
import { deleteGolfOfTheYearByID, postGolfOfTheYear } from '../api/golfClubAPI';

const AddGolferOfTheYear = (props) => {

    const {category, tournamentCalenderList, usersList, tournamentList, positionsList, gotyDetails, refetchGoty, refetchGotyUser} = props

    const [golferOfTheYearDetails,               setGolferOfTheYearDetails]            = useState({TournamentID: -1});    
 
    useEffect(() => {
        $('#GOTYModal').modal({
            backdrop: 'static',
        });
        $('#GOTYModal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
        
    }, []);

    useEffect(() => {
       if(gotyDetails.ID){
            setGolferOfTheYearDetails(gotyDetails);
       }
    }, [gotyDetails]);

    useEffect(() => {
       if(golferOfTheYearDetails){
            $('#txtDate').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date()
            });
            $('#txtDate').on('change', function (event) {
                updatGotyDetails('Date',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });
       }
    }, [golferOfTheYearDetails]);
  
    const addChargeHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        var form = $('#add-goty-form');
        
        form.validate({
            rules: {         
                ddlTournamentCalender: {
                    required: true
                },          
                ddlCategory: {
                    required: true
                },
                ddlPlayer: {
                    required: true,
                },         
                ddlTournament: {
                    required: true,
                },         
                txtTournament: {
                    required: true
                },          
                txtDate: {
                    required: true
                },          
                ddlPosition: {
                    required: true
                },          
                txtPoint: {
                    required: true
                }          
            },
            messages: {
                ddlTournamentCalender: {
                    required: "Please select calender"
                },
                ddlCategory: {
                    required: "Please select category"
                },
                ddlPlayer: {
                    required: "Please select player"
                },
                ddlTournament: {
                    required: "Please select Tournament"
                },
                txtTournament: {
                    required: "Please enter Tournament name"
                },
                txtDate: {
                    required: "Please enter from date"
                },
                ddlPosition: {
                    required: "Please select position"
                },
                txtPoint: {
                    required: "Please enter point"
                }
            }
        });
        
        if (!form.valid()) return;
        
 
        helper.StartProcessing(btn);

        try {

            const goty = {
                ID:                     golferOfTheYearDetails?.ID,
                UserID:                 golferOfTheYearDetails.UserID,
                TournamentID:           golferOfTheYearDetails.TournamentID,
                Tournament:             golferOfTheYearDetails.Tournament || golferOfTheYearDetails.TournamentName,
                Date:                   golferOfTheYearDetails.Date,
                PositionID:             golferOfTheYearDetails.PositionID,
                Point:                  golferOfTheYearDetails.Point,
                TournamentCalenderID:   golferOfTheYearDetails.TournamentCalenderID,            
                Category:               golferOfTheYearDetails.Category
                
            };
            await postGolfOfTheYear(goty)
            helper.StopProcessing(btn);

            $('#GOTYModal').modal('hide');
            if (golferOfTheYearDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'GolferOfTheYear updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
                refetchGotyUser()
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'GolferOfTheYear added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            refetchGoty()
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }

        setGolferOfTheYearDetails({});

        form.clearForm();
        form.validate().resetForm();
    }

    const updatGotyDetails = (field, value) => { 
        setGolferOfTheYearDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const deleteGotyhandler = async() => {
        try { 
            const swalResult = await swal.fire({
                title               : "Are you sure, you want to delete ?",
                text                : "You won't be able to revert this!",
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Proceed',
                preConfirm: async () => {
                    try {
                        await deleteGolfOfTheYearByID(golferOfTheYearDetails.ID);
                    } catch (err) {
                        swal.fire({
                            icon                : 'error',
                            titleText           : 'Error!',
                            text                : err.message,
                            buttonsStyling      : false,
                            confirmButtonClass  : 'btn btn-brand',
                        });
                        return;
                    }
                },
            });
            if (!swalResult.value) return;
            swal.fire({
                icon: 'success',
                titleText: 'User deleted successfully!',
                showConfirmButton: false,
                timer: 3500,
                onOpen: () => swal.hideLoading()    
            });
            refetchGoty()
            refetchGotyUser()
            setGolferOfTheYearDetails({});
            $('#GOTYModal').modal('hide');
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    return(
        <>
        <div className="modal fade" id="GOTYModal" tabIndex="-1" role="dialog" aria-labelledby="GOTYModal" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Golfer Of The Year</h5>
                        <button type="button" id="Buttonclose" className="close modalClose" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form className="kt-form" id="add-goty-form">
                        <div className="modal-body grey-background">
                            <div className="kt-portlet">
                                <div className="kt-portlet__body padding-t30 padding-b0">
                                    <div id="ModalHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Tournament Calender <span className="red">*</span></label>
                                            <div className="col-xl-4 col-lg-6">
                                                <Select 
                                                    id="ddlTournamentCalender" 
                                                    name="ddlTournamentCalender"
                                                    className="multi-custom"
                                                    placeholder="Select Calender"
                                                    value={
                                                        tournamentCalenderList && tournamentCalenderList.length > 0 && golferOfTheYearDetails.TournamentCalenderID
                                                        ? { 
                                                            label: tournamentCalenderList.find(m => m.ID === golferOfTheYearDetails.TournamentCalenderID)?.Name || '', 
                                                            value: golferOfTheYearDetails.TournamentCalenderID // Use TournamentCalenderID as the value
                                                        }
                                                        : null  // Fallback to null to maintain controlled state
                                                    }
                                                    onChange={(event) => updatGotyDetails('TournamentCalenderID', event.value)}
                                                    options={tournamentCalenderList.map(c => ({
                                                        label: c.Name,
                                                        value: c.ID
                                                    }))}
                                                />

                                            </div> 
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Category <span className="red">*</span></label>
                                            <div className="col-xl-4 col-lg-6">
                                                <Select 
                                                    id="ddlCategory" 
                                                    name="ddlCategory"
                                                    className="multi-custom"
                                                    placeholder="Select Category"
                                                    value={golferOfTheYearDetails && category.find((m) => m.value == golferOfTheYearDetails.Category) || ''}
                                                    onChange={(event) => updatGotyDetails('Category', event.value)}
                                                    options={category}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Player <span className="red">*</span></label>
                                            <div className="col-xl-4 col-lg-6">
                                                <Select 
                                                    id="ddlPlayer" 
                                                    name="ddlPlayer"
                                                    className="multi-custom"
                                                    placeholder="Select Player"
                                                    value={(usersList && usersList.length > 0 && golferOfTheYearDetails.UserID) && {label: usersList.find(m => m.ID == golferOfTheYearDetails.UserID)?.Name, value: golferOfTheYearDetails.UserID} || ''}
                                                    onChange={(event) => updatGotyDetails('UserID', event.value)}
                                                    options={usersList.map(c => ({
                                                        label: c.Name,
                                                        value: c.ID
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row" id="pnlddlTournament">
                                            <label className="col-form-label col-lg-3">Tournament  <span className="red">*</span></label>
                                            <div className="col-xl-4 col-lg-6"  >
                                                {(golferOfTheYearDetails.TournamentID == 0) ? (
                                                    <input 
                                                        id="txtTournament" 
                                                        name="txtTournament" 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={golferOfTheYearDetails.Tournament || ''}
                                                        onChange={(event) => updatGotyDetails('Tournament', event.target.value)} 
                                                    />
                                                ) : (
                                                    <Select 
                                                        id="ddlTournament" 
                                                        name="ddlTournament"
                                                        className="multi-custom"
                                                        placeholder="Select Tournament"
                                                        value={
                                                            (tournamentList && tournamentList.length > 0 && golferOfTheYearDetails.TournamentID && golferOfTheYearDetails.TournamentID !== -1)
                                                            ? { 
                                                                label: tournamentList.find(m => m.ID === golferOfTheYearDetails.TournamentID)?.Name || '', 
                                                                value: golferOfTheYearDetails.TournamentID  // Use TournamentID as the value
                                                            }
                                                            : null  // Fallback to null to maintain controlled state
                                                        }
                                                        onChange={(event) => {
                                                            updatGotyDetails('TournamentID', event.value); 
                                                            updatGotyDetails('Tournament', event.label); // Update Tournament name as well
                                                        }}
                                                        options={tournamentList.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                    )}
                                            </div>
                                            <div className="col-xl-4 col-lg-6">
                                                <button type="button" id="btnNew" className="btn btn-brand btn-icon-sm text-white d-inline margin-l10" onClick={() => updatGotyDetails('TournamentID', (golferOfTheYearDetails.TournamentID == 0 ) ? (golferOfTheYearDetails.TournamentID || -1) : 0 )}><i className="la la-plus"></i> {(golferOfTheYearDetails.TournamentID == 0 ) ? 'Search' : 'New'} </button>
                                            </div>
                                        </div>
                                        {(golferOfTheYearDetails.TournamentID == 0)  && (
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Date <span className="red">*</span></label>
                                                <div className="col-xl-4 col-lg-6">
                                                    <div className="input-group date dateWidth">
                                                        <input
                                                            id="txtDate"
                                                            name="txtDate"
                                                            type="text"
                                                            className="form-control date-input"
                                                            placeholder="Select date"
                                                            readOnly={true}
                                                            value={golferOfTheYearDetails.Date ? moment(golferOfTheYearDetails.Date).format('DD-MM-YYYY') : ''}
                                                        />                                                    
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="la la-calendar-check-o"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )} 
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Position <span className="red">*</span></label>
                                            <div className="col-xl-4 col-lg-6">
                                                <Select 
                                                    id="ddlPosition" 
                                                    name="ddlPosition"
                                                    className="multi-custom"
                                                    placeholder="Select Position"
                                                    value={
                                                        positionsList && positionsList.length > 0 && golferOfTheYearDetails.PositionID
                                                        ? { 
                                                            label: positionsList.find(m => m.ID === golferOfTheYearDetails.PositionID)?.Name || '', 
                                                            value: golferOfTheYearDetails.PositionID  // Use PositionID as the value
                                                        }
                                                        : null  // Fallback to null to maintain controlled state
                                                    }
                                                    onChange={(event) => updatGotyDetails('PositionID', event.value)}
                                                    options={positionsList.map(c => ({
                                                        label: c.Name,
                                                        value: c.ID
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Point <span className="red">*</span></label>
                                            <div className="col-xl-4 col-lg-6">
                                                <input 
                                                    id="txtPoint" 
                                                    name="txtPoint" 
                                                    type="text" 
                                                    className="form-control" 
                                                    value={golferOfTheYearDetails.Point || ''}
                                                    onChange={(event) => updatGotyDetails('Point', event.target.value)} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer ">
                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addChargeHandler}>{golferOfTheYearDetails?.ID ? 'Update' : 'Save'}</button>
                        {golferOfTheYearDetails.ID && <button id="btnDelete" type="button" className="btn btn-danger margin-l10" onClick={deleteGotyhandler}>Delete </button>}
                        <button id="btnModalCancel" type="button" data-dismiss="modal" className="btn btn-secondary margin-l10 modalClose">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
};

export default AddGolferOfTheYear;

