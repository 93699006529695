import RightSideDetails from "../global/rightSideDetails";
import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import Select from 'react-select';
import { getDataForGolfOfTheYear, getGOTYByCalenderIDandCategory, getGOTYByUserIDAndCalenderID } from "../api/golfClubAPI";
import AddGolferOfTheYear from "./addGolferOfTheYear";

const GolferOfTheYear = (props) => {

    const [category ,                   setCategory]                 = useState([{ value:  'Men', label: 'Men'}, { value:  'Women', label: 'Women'}]);
    const [tournamentCalenderList,      setTournamentCalenderList]   = useState([]);
    const [usersList,                   setUsersList]                = useState([]);
    const [tournamentList,              setTournamentList]           = useState([]);
    const [positionsList,               setPositionsList]            = useState([]);
    const [rightSideDetails,            setRightSideDetails]         = useState(false);
    const [type,                        setType]                     = useState({'Category': category[0]?.label});
    const [isInit,                      setIsInit]                   = useState(true);
    const [isInitUser,                  setIsInitUser]               = useState(true);
    const [gotyList,                    setGotyList]                 = useState([]);
    const [gotyUserList,                setGotyUserList]             = useState([]);
    const [gotyTable,                   setGotyTable]                = useState(null);
    const [gotyDetails,                 setGotyDetails]              = useState(null);
 
    useEffect(() => {
        props.title('Golfer Of The Year', 'golfer-of-the-year');
        fetchDataForGolfOfTheYear();
    }, []);

    useEffect(() => {
        if (rightSideDetails) {
            Config.Core.OpenDrawer();
        }else{
            setIsInitUser(true);
            fetchGolfOfTheYear(type?.TournamentCalenderID, type?.Category);
        }
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [gotyList]);

    useEffect(() => {       
        if (!isInitUser) {
            fillUserDetailTable();        
        }
    }, [gotyUserList]);

    useEffect(() => {
        if (type?.TournamentCalenderID && type?.Category) {
            fetchGolfOfTheYear(type?.TournamentCalenderID, type?.Category);
        }
    }, [type]);

    const fetchDataForGolfOfTheYear = async () => {
        try {
            const response = await getDataForGolfOfTheYear();   
            if (response.TournamentCalender.length > 0) {
                updateTypeDetails('TournamentCalenderID', response.TournamentCalender[0].ID);
            }
            setTournamentCalenderList(response.TournamentCalender);
            setUsersList(response.Users);
            setTournamentList(response.Tournaments);
            setPositionsList(response.Positions);             
        } catch (error) {
            swal.fire({
                icon: 'error',
                titleText: error.message
            });
        }
    };

    const fetchGolfOfTheYear = async (calenderID, category) => {
        try {
            const response = await getGOTYByCalenderIDandCategory(calenderID, category);
            isInit ? setIsInit(false) : $('#tblGolfOfTheYear').KTDatatable().destroy();
            setGotyList(response);
        } catch (err) {
            swal.fire({
                icon: 'error',
                titleText: err.message
            });
        }
    };

    const fetchGOTYByUserIDAndCalenderID = async (userID, calenderID) => {
        try {
            const response = await getGOTYByUserIDAndCalenderID(userID, calenderID);
            isInitUser ? setIsInitUser(false) : $('#tblUserDetail').KTDatatable().destroy();
            setGotyUserList(response);
        } catch (err) {
            swal.fire({
                icon: 'error',
                titleText: err.message
            });
        }
    };

    const updateTypeDetails = (field, value) => {
        setType((prevType) => ({
            ...prevType,
            [field]: value,
        }));
    };

    const exportSheet = (btnType) => {
        const fileName = `GolfOfTheYear`;

        gotyTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: fileName
        });
        
        if (btnType === 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }

        $('.tableexport-caption').addClass('hidden');
    };

    const fillDataTable = () => {
        const dataTable = $('#tblGolfOfTheYear').KTDatatable({
            data: {
                saveState: false,
                source: gotyList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Position',
                    title: 'Position',
                    width: 80,
                },
                {
                    field: 'Name',
                    title: 'Player',
                },
                {
                    field: 'TotalPoint',
                    title: 'Points',
                    width: 120,
                },
                {
                    field: '',
                    title: '',
                    width: 80,
                    textAlign: 'right',
                    template: (row) => showEditButton(row),
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblGolfOfTheYear .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        const gotyTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `GolfOfTheYear`,
        });
        setGotyTable(gotyTable);
        $('.tableexport-caption').addClass('hidden');

        function showEditButton(row) {
            var optString = '';

            optString += '<center>'

            optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>`

            optString += '</center>'

            return optString;
        }
        dataTable.on('click', '.btn-edit', (e) => {
            const index = $(e.currentTarget).parents('tr').index();
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                fetchGOTYByUserIDAndCalenderID(dataTable.dataSet[index].UserID, dataTable.dataSet[index].TournamentCalenderID);
                setRightSideDetails(true);
            }
        });
    };

    const fillUserDetailTable = () => {
        const dataTable = $('#tblUserDetail').KTDatatable({
            data: {
                saveState: false,
                source: gotyUserList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'PositionName',
                    title: 'Position',
                    width: 120,
                },
                {
                    field: 'TournamentName',
                    title: 'Tournament',
                },
                {
                    field: 'Point',
                    title: 'Points',
                    width: 80,
                },
                {
                    field: 'UserID',
                    title: '',
                    width: 80,
                    textAlign: 'right',
                    template: (row) => showEditButton(row),
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        function showEditButton(row) {
            var optString = '';

            optString += '<center>'

            optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>`

            optString += '</center>'

            return optString;
        }

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblUserDetail .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        dataTable?.on('click', '.btn-edit', (e) => {
            const index = $(e.currentTarget).parents('tr').index();
            if (dataTable?.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setGotyDetails(dataTable.dataSet[index]);
            }
        });
    };

    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setRightSideDetails(false);
    };

    return( 
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop: '0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-2 text-right">Tournament Calender </label>
                                    <div className="form-group col-sm-2">
                                        <div>
                                            <Select 
                                                id="ddlTournamentCalender" 
                                                name="ddlTournamentCalender"
                                                className="multi-custom"
                                                placeholder="Select Tournament Calender"
                                                value={
                                                    tournamentCalenderList && tournamentCalenderList.length > 0 && type.TournamentCalenderID
                                                    ? { 
                                                        label: tournamentCalenderList.find(m => m.ID === type.TournamentCalenderID)?.Name || '', 
                                                        value: type.TournamentCalenderID 
                                                    }
                                                    : null
                                                }
                                                onChange={(event) => updateTypeDetails('TournamentCalenderID', event.value)}
                                                options={tournamentCalenderList.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>

                                    <label className="col-form-label text-right">Category </label>
                                    <div className="form-group col-sm-2">
                                        <div >
                                            <Select 
                                                id="ddlCategory" 
                                                name="ddlCategory"
                                                className="multi-custom"
                                                placeholder="Select Category"
                                                value={type && category.find((m) => m.value == type.Category) || ''}
                                                onChange={(event) => updateTypeDetails('Category', event.value)}
                                                options={category}
                                            />
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head-label">
                                        <div className="row align-items-center margin-l10">
                                            <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                                <div className="kt-input-icon kt-input-icon--left">
                                                    <input type="text" className="form-control clientSearch" placeholder="Search Player..." id="generalSearch" />
                                                    <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-1 col-sm-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('xlsx')}
                                                >Excel
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('csv')}
                                                >Csv 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <div className="kt-portlet__head-wrapper">
                                            <div className="kt-portlet__head-actions">
                                                <button type="button" id="buttonNew" className="btn btn-brand btn-icon-sm text-white d-inline margin-l10" onClick={() => setGotyDetails({})}><i className="la la-plus"></i>New </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                {/* <h5 id="lblTournamentCalender" className="lblTournamentCalender"></h5> */}
                                <div id="tblGolfOfTheYear" className="table table-bordered table-hover custom-datatable"></div>
                                {rightSideDetails && (<RightSideDetails title="Golfer Of The year" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet kt-portlet--mobile">
                                        <p id="userName" className="UserCSS"> {gotyUserList[0]?.Name}</p>
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <div className="kt-portlet__body kt-portlet__body--fit">
                                                <div id="tblUserDetail" className="table table-bordered table-hover custom-datatable"></div>
                                            </div>
                                        </div>
                                    </div> 
                                </RightSideDetails>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {gotyDetails && (
                <AddGolferOfTheYear
                    show={gotyDetails}
                    gotyDetails={gotyDetails}
                    onDismissModal={setGotyDetails}
                    category={category}             
                    tournamentCalenderList={tournamentCalenderList}
                    usersList={usersList}             
                    tournamentList={tournamentList}        
                    positionsList={positionsList}                            
                    refetchGoty={() => fetchGolfOfTheYear(type?.TournamentCalenderID, type?.Category)}                            
                    refetchGotyUser={() => fetchGOTYByUserIDAndCalenderID(gotyUserList[0]?.UserID, gotyUserList[0]?.TournamentCalenderID)}                            
                />
            )}
        </>
    )
};

export default GolferOfTheYear;