import React, { useEffect, useState } from 'react'
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import Select from 'react-select';
import { deleteMatBook, getGreenFee, getMatBook, getMatBookSheet, getMatMasterAll, postMatBook } from '../api/matAPI';
import { getGolfUsers, getUserAll } from '../api/usersAPI';

const MatBookingSheet = (props) => {

    const [isInit,                  setIsInit]                      = useState(true);
    const [rightSideDetails,        setRightSideDetails]            = useState(false);
    const [selectedDate,            setSelectedDate]                = useState(moment().toDate());
    const [selectedMat,             setSelectedMat]                 = useState(null);
    const [allMaster,               setAllMaster]                   = useState([]);
    const [bookSheetList,           setBookSheetList]               = useState([]);
    const [bookSheet,               setBookSheet]                   = useState({});
    const [usersList,               setUsersList]                   = useState([]);
    const [bookingTable,            setBookingTable]                = useState(null);
    const [holiday,                 setHoliday]                     = useState(null);
    const [serverTime,              setserverTime]                  = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
    
    useEffect(() => {
        props.title("Booking Sheet", "mat-sheet");
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        });      
        fetchMatMaster();
    }, []);

    useEffect(() => {
        if (selectedDate && selectedMat) {
            fetchBookSheet(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedMat.ID)
        }
    }, [selectedDate, selectedMat]);

    useEffect(() => {
        if(allMaster && allMaster.length > 0) {
            setSelectedMat(allMaster[0])
        }
    }, [allMaster])
    
    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }else{
            setBookSheet({})
        }
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [bookSheetList, holiday, serverTime]);

    const resetUser = () => {
        setBookSheet(a => ({...a, UserID: ''}))
    }

    const updateBookingDetails = (field, value) => { 
        setBookSheet((d) => {
            return { ...d, [field]: value };
        });
    };

    const fetchBookSheet = async (selectedDate, dayName, matID) => {
        try {
            const response = await getMatBook({selectedDate, dayName, matID, type: 'matBookSheet'});
            isInit ? setIsInit(false) : $('#tblBookSheet').KTDatatable().destroy(); 
            setBookSheetList(response.bookSheet);
            setHoliday(response.holiday);
            setserverTime(response.serverTimestamp);
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchMatMaster = async () => {
        try {
            const mat = await getMatMasterAll();
            setAllMaster(mat); 
        } catch (error) {
            swal.fire({ icon :'error', titleText : error.message })
        }
    }

    const fetchUsers = async () => {
        try {
            const users = await getGolfUsers('user');
            if (users && users.length > 0) {
                setUsersList(users.map(u => ({
                    ...u,
                    Name : `${u.FirstName} ${u.LastName}`
                })))
            }
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const cancelBookingHandler = async () => {
        const btn = $('#btnCancel');
        helper.StartProcessing(btn);
        try {
            await deleteMatBook(bookSheet.ID)
            helper.StopProcessing(btn);
            fetchBookSheet(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedMat.ID)
            // setTimeout(() => {
            // }, 1000);
            closeDrawer();
            swal.fire({
                icon: 'success',
                titleText: 'Booking deleted successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const addBookingHandler = async () => {
        const btn = $('#btnSave');
        if (bookSheet.Bucket == 0 || !bookSheet.Bucket) {
            swal.fire({
                icon                  : 'warning',
                text                  : "Enter number of Bucket",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }

        if (!bookSheet.UserID) {
            swal.fire({
                icon                  : 'warning',
                text                  : "Please select player",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }
    
        helper.StartProcessing(btn);
        try {
            await postMatBook({
                ...bookSheet,
                MatID       : selectedMat.ID,
                Type        : 'Mat',
                BookDate    : moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), 
            })
            closeDrawer();
            fetchBookSheet(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedDate, 'DD-MM-YYYY').format('dddd'), selectedMat.ID)
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }

    }

    const validateBucket = (field, value) => {
        if (value > 5)
            value = 5;

        if (value < 0)
            value = 1

        // if (value = 0)
        //     value = 1
        updateBookingDetails(field, value)
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    function exportSheet(btnType) {
        let fileName = `${moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} - Mat Booking Sheet}`;

        bookingTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: fileName // pass in a new set of properties
        });
        
        if (btnType == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        
        $('.tableexport-caption').addClass('hidden');
    }

    function fillDataTable() {
        swal.close();

        if (holiday != null) {
            $('#lblHoliday').text("Holiday : " + holiday.Name);
        } else {
            $('#lblHoliday').text("");
        }

        const dataTable = $('#tblBookSheet').KTDatatable({
            data: {
                saveState: false,
                source   : bookSheetList,
                pageSize : 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable  : true,
            pagination: true,
            search    : { input: $('#generalSearch')},
            columns: [               
                {  field: 'Slot',  title: 'Slot',
                    template: function (row) {
                        return `${row.SlotFrom} To ${row.SlotTo}`
                    },
                    width:250 
                },
                {
                    field: 'Status',
                    title: 'Status',
                    template: function (row) {
                        if (row.Status != null) {
                            return `<span class="badge badge-danger">${row.Status}</span>`
                        } else {
                            return '<span class="badge badge-success"> Available </span>'
                        }
                    },
                    width: 120,
                },
                {
                    field: 'Player',
                    title: 'Player',
                    width: 120,
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    class: "clickable",
                    template: function (row) {

                        if ((row.Status == "Booked" || row.Status == null)) {
                            return showEditButton(row)
                        } else {
                            return '';
                        }
                    }
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblBookSheet .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        // export to excel
        const BookingTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Mat Booking Sheet`,
        });
        setBookingTable(BookingTable);
        $('.tableexport-caption').addClass('hidden');
        
        function showEditButton(row) {

            const formattedDate = moment($('#txtDate').val(), 'DD/MM/YYYY').format('DD-MMM-YYYY');

            var optString = '';

            const isEdit = moment(moment(serverTime.ServerDate).format('DD-MMM-YYYY')).isSameOrBefore(formattedDate);

            optString += '<center>'

            if (row.Comment) optString += `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content="${row.Comment}"><i class="fa fa-info"></i></button>`

            if (isEdit) optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>`

            optString += '</center>'

            return optString;
        }

        dataTable.on('click', '.btn-edit', function (ss) {
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setBookSheet({
                    ...dataTable.dataSet[index], 
                    Bucket      : dataTable.dataSet[index].Bucket ? dataTable.dataSet[index].Bucket : 1,
                    Discount    : dataTable.dataSet[index].Discount === 0 ? '' : dataTable.dataSet[index].Discount
                })
                fetchUsers();
                setRightSideDetails(true); 
            } 
        });
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">Date </label>
                                    <div className="form-group col-xs-2 col-sm-3">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date"
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            /> 
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Mat </label>
                                    <div className="form-group col-xl-2 col-sm-3">
                                        <Select
                                            onChange={(event) => setSelectedMat(event.value)}
                                            name="ddlSelectUser"
                                            value={(selectedMat && (allMaster && allMaster.length > 0)) && {label : selectedMat.Number, value: selectedMat}}
                                            id="ddlSelectUser"
                                            className="ddlSelectUser"
                                            options={allMaster.map(c => ({
                                                label: `${c.Number}`,
                                                value: c
                                            }))}
                                        />
                                    </div>
                                    <div className="form-group col-xl-3 col-sm-3">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-sm-2">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('xlsx')}
                                                >Excel
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('csv')}
                                                >Csv 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 id="lblHoliday" className="lblHoliday"></h5>
                            <div id="tblBookSheet" className="table table-bordered table-hover custom-datatable"></div>
                            {rightSideDetails && 
                                <RightSideDetails title="Booking Sheet" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t30 padding-b0">
                                            <form id="ModalHolder" className="kt-form kt-form--label-right padding-10">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Date</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblDate" 
                                                            disabled="disabled" 
                                                            name="lblDate" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                        />
                                                    </div>
                                                </div>
                                                <div id="pnlSlot" className="form-group row">
                                                    <label className="col-form-label col-lg-3">Slots</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="lblSlot" 
                                                            disabled="disabled" 
                                                            name="lblSlot" 
                                                            type="text"
                                                            onChange={updateBookingDetails} 
                                                            className="form-control" 
                                                            value={`${bookSheet.SlotFrom} To ${bookSheet.SlotTo}`}
                                                        />
                                                    </div>
                                                </div>
                                                <div id="UserHolder">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">User</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group" >
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(usersList && usersList.length > 0 && bookSheet.UserID) && {label: `${usersList.find(m => m.ID == bookSheet.UserID).Name} (${usersList.find(m => m.ID == bookSheet.UserID).Mobile})`, value: bookSheet.UserID} || ''} 
                                                                        onChange={(event) => updateBookingDetails('UserID', event.value)}
                                                                        name="ddlUser"
                                                                        placeholder="Select User"
                                                                        id="ddlUser"
                                                                        className="ddlUser"
                                                                        options={usersList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="input-group-append" style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetUser}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div>
                                                                {/* <button id="btnNewUser" className="btn btn-label-brand margin-l10" type="button" data-toggle="modal" data-target="#modalUser" onClick={() => setShowAddUser(true)}>New</button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Bucket</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtBucket" 
                                                            name="txtBucket" 
                                                            type="number"
                                                            className="form-control" 
                                                            value={bookSheet.Bucket || ''}
                                                            onChange={(event) => validateBucket('Bucket', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Comment</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <textarea 
                                                            id="txtComment" 
                                                            name="txtComment" 
                                                            className="form-control" 
                                                            rows="3"
                                                            value={bookSheet.Comment || ''}
                                                            onChange={(event) => updateBookingDetails('Comment', event.target.value)} 
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-lg-12 text-right">
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addBookingHandler}>{bookSheet.ID ? 'Update Booking' : 'Save Booking' }</button>
                                                        {bookSheet && 
                                                            bookSheet.ID && <button id="btnCancel" type="button" className="btn btn-warning d-inline-block margin-l10" onClick={cancelBookingHandler}>Cancel Booking</button>
                                                        }
                                                        <button id="btnCloseDrawer" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RightSideDetails>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MatBookingSheet;