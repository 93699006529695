import React, { useState, useEffect } from 'react'
import { deleteCalendar, getActiveSportTaggingByCoachID, getCalendarByCoachID, getCoachAdvanceBookingByCoachID, getCoachBookingRate, getCoachMasterAll, getDays, getSportTaggingByCoachID, postCalendar, postCoachBookingRate, updateCalendarActive, updateCoachAdvanceBooking } from '../api/coachAPI';
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Loading from '../global/loading';
import moment from 'moment';

const Calender = (props) => {

    const [coachList,           setCoachList]           = useState([]);
    const [selectedCoach,       setSelectedCoach]       = useState(null);
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [navState,            setNavState]            = useState('');
    const [days,                setDays]                = useState([]);
    const [calendarDetails,     setCalendarDetails]     = useState({SportID : 0});
    const [editTime,            setEditTime]            = useState(false);
    const [calendarLoading,     setCalendarLoading]     = useState(false);
    const [advLoading,          setAdvLoading]          = useState(false);
    const [rateLoading,         setRateLoading]         = useState(false);
    const [calendar,            setCalendar]            = useState([]);
    const [sportsTagged,        setSportsTagged]        = useState([]);
    const [advanceBookingList,  setAdvanceBookingList]  = useState([]);
    const [advanceBooking,      setAdvanceBooking]      = useState({});
    const [rateDetails,         setRateDetails]         = useState([]);
    const [activeSportsTagged,  setActiveSportsTagged]  = useState([]);

    useEffect(() => {         
        props.title("Calendar", "calendar")
        fetchCoachMaster();
    }, []);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [coachList]);

    useEffect(() => {
        if(selectedCoach) {
        } else {
        }
    }, [selectedCoach])
    
    useEffect(() => {  
        if (rightSideDetails) {
            Config.Core.OpenDrawer()
            setNavState('Calendar')
            fetchDays();
        } else {
            setSelectedCoach(null)
            setNavState('')
        }
    }, [rightSideDetails]);

    const updateRateDetails = (userType, type, field, value) => {
        setRateDetails((prevRate) => {
            return prevRate.map(detail => ({
                ...detail,
                Rate: detail.UserType == userType && detail.Type == type ? parseFloat(value) || 0 : detail.Rate
            }))
        })
    }

    const initializeTimepickers = () => {
        $('.startTime').timepicker({ minuteStep: 30, defaultTime: '05:00 AM' });
        $('.startTime').on('change', ({ target }) => updateCalendarDetails("StartTime", target.value));
        $('.endTime').timepicker({ minuteStep: 30, defaultTime: '05:00 AM' });
        $('.endTime').on('change', ({ target }) => updateCalendarDetails("EndTime", target.value));
    };

    const updateCalendarDetails = (field, value) => {   
        setCalendarDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateAdvanceBookingDetails= (field, value) => {
        setAdvanceBooking(d => ({
            ...d,
            [field]: value
        }))
    }

    const fetchDays = async() => {
        try {
            const response = await getDays();
            setDays(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const cardTimeClick = () => {
        setCalendarDetails({SportID: 0})
        initializeTimepickers()
    }

    const cardAdvClick = () => {
        setAdvanceBooking({})
    }

    const cardRateClick = () => {
        setAdvanceBooking({})
    }

    const fetchCoachMaster = async () => {
        try {
            const coachMaster =  await getCoachMasterAll();
            isInit ? setIsInit(false) : $('#tblCoach').KTDatatable().destroy();
            setCoachList(coachMaster)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblCoach').KTDatatable({
            data: {
                saveState: false,
                source: coachList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.ProfilePhoto && row.ProfilePhoto != '') ? `<img src="${row.ProfilePhoto}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                </div>
                            </div>`
                    }

                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        if (row.Active == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblCoach .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSelectedCoach(dataTable.dataSet[index]);
                fetchSportTaggingByCoachID(dataTable.dataSet[index].ID)
                fetchActiveSportTaggingByCoachID(dataTable.dataSet[index].ID)
                setRightSideDetails(true)
            }    
        });

    }

    const fetchSportTaggingByCoachID = async (coachID) => {
        try {
            const response = await getSportTaggingByCoachID(coachID);
            setSportsTagged(response)
        } catch (err) {
            helper.reuseableSwal('error', 'Error!', error.message)
        }
    }

    const fetchActiveSportTaggingByCoachID = async (coachID) => {
        try {
            const response = await getActiveSportTaggingByCoachID(coachID);
            setActiveSportsTagged(response)
        } catch (err) {
            helper.reuseableSwal('error', 'Error!', error.message)
        }
    }

    const fetchCalendar = async(caochID, dayID) => {
        try {
            setCalendarLoading(true)
            const response = await getCalendarByCoachID(caochID, dayID)
            setCalendar(response);
            setCalendarLoading(false)
        } catch (error) {
            setCalendarLoading(false)
            helper.reuseableSwal('error', 'Error!', error.message)
        }
    }

    const fetchCoachBookingRate = async(caochID, sportID) => {
        try {
            setRateLoading(true)
            const response = await getCoachBookingRate(caochID, sportID)
            setRateDetails(response);
            setRateLoading(false)
        } catch (error) {
            setRateLoading(false)
            helper.reuseableSwal('error', 'Error!', error.message)
        }
    }

    const fetchCoachAdvanceBookingByCoachID = async(caochID) => {
        try {
            setAdvLoading(true)
            const response = await getCoachAdvanceBookingByCoachID(caochID)
            setAdvanceBookingList(response);
            setAdvLoading(false)
        } catch (error) {
            setAdvLoading(false)
            helper.reuseableSwal('error', 'Error!', error.message)
        }
    }

    const editCalendarHandler = (time) => {
        setCalendarDetails(time);
        setEditTime(true)
    }

    const editCoachAdvanceBookingHandler = (value) => {
        setAdvanceBooking(value)
    }

    const removeCalendarHandler = async (id, dayID) => {
        try {
            await deleteCalendar(id);
            fetchCalendar(selectedCoach.ID, dayID)
            swal.fire({
                toast             : true,
                icon              : 'success',
                titleText         : 'time deleted successfully!',
                position          : 'bottom-end',
                showConfirmButton : false,
                timer             : 1500,
                animation         : false,
                customClass       : {
                    popup: 'margin-20',
                },
            });
        } catch (err) {
            helper.reuseableSwal('error', 'Error!', err.message)
            return;
        }
    }

    const updateActive = async (id, active) => {
        try {
            await updateCalendarActive(id, active);
        } catch (err) {
            helper.reuseableSwal('error', 'Error!', err.message)
            return;
        }
    }

    const updateAdvanceBooking = async () => {

        const btn = $('#update-advBooking-btn');
        if (!advanceBooking.ID) {
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Warning!',
                text                  : "Please select sport",
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return;
        }

        helper.StartProcessing(btn);

        try {
            await updateCoachAdvanceBooking(advanceBooking)
            fetchCoachAdvanceBookingByCoachID(selectedCoach.ID)
            setAdvanceBooking({})
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `update successfully!`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });
        }catch (err) {
            helper.StopProcessing(btn);
            helper.reuseableSwal('error', 'Error!', err.message)
            return;
        }
    }

    function isOverlap(existingSlot, newSlot) {
        const existingStart = moment(existingSlot.StartTime, "h:mm A");
        const existingEnd = moment(existingSlot.EndTime, "h:mm A");
        const newStart = moment(newSlot.StartTime, "h:mm A");
        const newEnd = moment(newSlot.EndTime, "h:mm A");

        return newStart.isBefore(existingEnd) && newEnd.isAfter(existingStart);
    }

    const saveRateDetails = async () => {
        const btn = $('#btnSave');
        helper.StartProcessing(btn);
        try {
            await postCoachBookingRate(rateDetails)
            fetchCoachBookingRate(selectedCoach.ID, rateDetails[0].SportID)
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `Rate save successfully!`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });
        }catch (err) {
            helper.StopProcessing(btn);
            helper.reuseableSwal('error', 'Error!', err.message)
            return;
        }
    }


    const AddCalendar = async (dayID) => {
        const btn = $('#add-calendar-btn');

        if (!calendarDetails.StartTime || !calendarDetails.EndTime) {
            helper.reuseableSwal('warning', 'Warning!', "Please select time")
            return;
        }

        const startTime = moment(calendarDetails.StartTime);
        const endTime = moment(calendarDetails.EndTime);

        if (startTime.isAfter(endTime)) {
            helper.reuseableSwal('warning', 'Warning!', "Invalid Slot")
            return;
        }

        if (calendarDetails.SportID == 0) {
            helper.reuseableSwal('warning', 'Warning!', "Please select sport")
            return;
        }

        let hasOverlap = calendar.some(slot => slot.ID != calendarDetails.ID && isOverlap(slot, calendarDetails));

        if (hasOverlap) {
            helper.reuseableSwal('warning', 'Warning!', "Slot already exists")
            return;
        }

        helper.StartProcessing(btn);

        try {
            await postCalendar({...calendarDetails, DayID: dayID, CoachID: selectedCoach.ID});
            setCalendarDetails({SportID: 0})
            if (calendarDetails.ID) {
                setEditTime(false)
            }
            fetchCalendar(selectedCoach.ID, dayID)
            helper.StopProcessing(btn);
            swal.fire({
                toast: true,
                icon: 'success',
                titleText: `Package time ${calendarDetails.ID ? 'update' : 'add'} successfully!`,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,                                       
            });
        }catch (err) {
            helper.StopProcessing(btn);
            helper.reuseableSwal('error', 'Error!', err.message)
            return;
        }
    }
    
    const closeDrawer = () => {
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false)
            setSportsTagged([])
            setActiveSportsTagged([])
        }, 200);        
    }

    return (
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Coach..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblCoach" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && 
                                <RightSideDetails title="Coach Details" onCloseDrawer={closeDrawer}>
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <div className="kt-widget kt-widget--user-profile-3">
                                                <div className="kt-widget__top">
                                                    {selectedCoach.ProfilePhoto && selectedCoach.ProfilePhoto != '' ? 
                                                        <img className="kt-widget__pic kt-widget__pic" src={selectedCoach.ProfilePhoto} alt="user-profile"/> :
                                                        <div
                                                            // onClick={() => setShowUserDetails(userDetails)}
                                                            className={`kt-widget__pic kt-widget__pic--brand
                                                            } kt-font-brand
                                                            } kt-font-boldest kt-hidden-`}>
                                                            {selectedCoach.Name.slice(0, 1)}
                                                        </div>
                                                    }
                                                    <div className="kt-widget__content">
                                                        <div className="kt-widget__head">
                                                            <span className="kt-widget__username">
                                                                {selectedCoach.Name}
                                                            </span>
                                                        </div>
                                                        <div className="kt-widget__subhead padding-t0">
                                                            <span className="padding-r10">
                                                                <i className="flaticon2-phone"></i>
                                                                {selectedCoach.Mobile}
                                                            </span>
                                                            <br></br>
                                                            <span className="padding-r10">
                                                                <i className="flaticon2-new-email"></i>
                                                                {selectedCoach.Email}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-portlet kt-portlet--mobile">
                                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                            <div className="kt-portlet__head-label">
                                                <nav className="file-nav navbar navbar-top">
                                                    <a className={navState === "Calendar" ? "top-nav-active" : "" } onClick={() => setNavState("Calendar")}  >  
                                                        Calendar
                                                    </a>
                                                    <a className={navState === "Rate" ? "top-nav-active" : "" } onClick={() => setNavState("Rate")}  >  
                                                        Coach Rate
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__body padding-30">
                                            {navState === 'Calendar' && (
                                                <>
                                                    <form className="kt-form kt-form--label-right" id="add-calendar-form" onSubmit={e => e.preventDefault()}>
                                                        <div className="accordion accordion-toggle-arrow" id="accordionExample3">
                                                            <div className="card">
                                                                <div className="card-header" id="menuHeading" 
                                                                    onClick={() => fetchCoachAdvanceBookingByCoachID(selectedCoach.ID)}
                                                                >
                                                                    <div className="card-title collapsed" id="advanceDay"  data-toggle="collapse" data-target={`#${helper.SanitizeUrl('advanceDay')}`} onClick={cardAdvClick} aria-expanded="false" aria-controls={helper.SanitizeUrl('advanceDay')}>
                                                                        <div className="col-12">
                                                                            <span>Advance Day</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id={helper.SanitizeUrl('advanceDay')} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                                    <div className="card-body">
                                                                        <div className="kt-widget4">
                                                                            {advanceBooking.ID && (
                                                                                <div className="form-group row">
                                                                                    <div className="col-3">
                                                                                        <label className="col-form-label text-right width-50 padding-t0 padding-b6"> Sport Name </label>
                                                                                        <div className="">
                                                                                            <input id='txtSportName' name="txtSportName" type="text" className="form-control"
                                                                                                value={advanceBooking.SportName || ''} disabled
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <label className="col-form-label text-right width-50 padding-t0 padding-b6"> Advance Booking </label>
                                                                                        <div className="">
                                                                                            <input id='advanceBooking' name="advanceBooking" type="number" className="form-control"
                                                                                                value={advanceBooking.AdvBooking || ''} 
                                                                                                onChange={(event) => updateAdvanceBookingDetails('AdvBooking', event.target.value)}                                               
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-3 margin-t25'>
                                                                                        <div className="btn-group btn-block" role="group">

                                                                                            <button
                                                                                                id="update-advBooking-btn"
                                                                                                className="btn btn-brand btn-block margin-0 margin-t5"
                                                                                                style={{ maxWidth: '70%' }}
                                                                                                onClick={updateAdvanceBooking}
                                                                                            >
                                                                                                Update
                                                                                            </button>
                                                                                            <button
                                                                                                style={{ maxWidth: '30%' }}
                                                                                                className="btn btn-secondary btn-block margin-0 margin-t5 padding-0"
                                                                                                onClick={() => {
                                                                                                    setAdvanceBooking({});
                                                                                                    // setEditTime(false);
                                                                                                }}
                                                                                            >
                                                                                                <i
                                                                                                    className="la la-times"
                                                                                                    style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                                                />
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {advLoading ? (<Loading/>) : (
                                                                                <>

                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                                                <table className="kt-datatable__table">
                                                                                                    <thead
                                                                                                        className="kt-datatable__head"
                                                                                                        style={{ display: 'block' }}>
                                                                                                        <tr className="kt-datatable__row block-row">
                                                                                                            <th className="kt-datatable__cell text-clip" width="30%">
                                                                                                                <span>Sport</span>
                                                                                                            </th>
                                                                                                            <th className="kt-datatable__cell text-clip text-center" width="30%">
                                                                                                                <span>Advance Booking</span>
                                                                                                            </th>
                                                                                                            <th className="kt-datatable__cell text-clip text-center" width="30%">
                                                                                                                <span className="margin-r20">Action</span>
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {advanceBookingList.length > 0 ? (
                                                                                        <div 
                                                                                            className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                                                            <table className="kt-datatable__table table-striped">
                                                                                                <tbody
                                                                                                    className="kt-datatable__body">
                                                                                                    {advanceBookingList.map((p, i) => (
                                                                                                        <tr
                                                                                                            key={i}
                                                                                                            className="kt-datatable__row block-row">
                                                                                                            <td className="kt-datatable__cell text-clip" width="30%">
                                                                                                                <span>{p.SportName}</span>
                                                                                                            </td>
                                                                                                            <td className="kt-datatable__cell text-clip text-center" width="30%">
                                                                                                                <span>{p.AdvBooking}</span>
                                                                                                            </td>
                                                                                                            <td className="kt-datatable__cell text-clip text-center" width="30%">
                                                                                                                <button 
                                                                                                                    type="button"
                                                                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                                                
                                                                                                                    onClick={() => editCoachAdvanceBookingHandler(p)}>  
                                                                                                                    <i className="flaticon2-edit"></i>
                                                                                                                </button>
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                                            {(days && days.length > 0) && (
                                                                days.map((m, i) => (
                                                                    <div className="card" key={i}>
                                                                        <div className="card-header" id="menuHeading" 
                                                                            onClick={() => fetchCalendar(selectedCoach.ID, m.ID)}
                                                                        >
                                                                            <div className="card-title collapsed" id={i}  data-toggle="collapse" data-target={`#${helper.SanitizeUrl(m.Name)}`} onClick={cardTimeClick} aria-expanded="false" aria-controls={helper.SanitizeUrl(m.Name)}>
                                                                                <div className="col-12">
                                                                                    <span>{m.Name}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id={helper.SanitizeUrl(m.Name)} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                                            <div className="card-body">
                                                                                <div className="kt-widget4">
                                                                                    <div className="form-group row">
                                                                                        <div className="col-3">
                                                                                            <label className="col-form-label text-right width-50 padding-t0 padding-b6"> Start Time </label>
                                                                                            <div className="input-group timepicker width-200">
                                                                                                <input id={`txtStartTime${i}`} name="txtStartTime" type="text" className="form-control startTime" 
                                                                                                    readOnly={true} placeholder="Select time" 
                                                                                                    value={calendarDetails.StartTime || ''}                                               
                                                                                                />
                                                                                                <div className="input-group-append">
                                                                                                    <span className="input-group-text"> <i className="la la-clock-o"></i> </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-3">
                                                                                            <label className="col-form-label text-right width-50 padding-t0 padding-b6"> End Time </label>
                                                                                            <div className="input-group timepicker width-200">
                                                                                                <input id={`txtEndTime${i}`} name="txtEndTime" type="text" className="form-control endTime" 
                                                                                                    readOnly={true} placeholder="Select time" 
                                                                                                    value={calendarDetails.EndTime || ''}                                               
                                                                                                />
                                                                                                <div className="input-group-append">
                                                                                                    <span className="input-group-text"> <i className="la la-clock-o"></i> </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-3'>
                                                                                            <label className="col-form-label text-right width-50 padding-t0 padding-b6"> Select Sport </label>
                                                                                            <select
                                                                                                id="ddlTaggedSport" name="ddlTaggedSport" title="Select" className="form-control"
                                                                                                value={calendarDetails?.SportID}
                                                                                                onChange={({target}) => updateCalendarDetails('SportID', target.value)}
                                                                                            >
                                                                                                <option value={0}> Select Sport </option>
                                                                                                {activeSportsTagged.map((t, i) => (
                                                                                                    <option value={t.SportID} key={i}> {t.Name} </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className='col-3 mt-4'>
                                                                                            {editTime ? (
                                                                                                <div className="btn-group btn-block" role="group">

                                                                                                    <button
                                                                                                        id="add-calendar-btn"
                                                                                                        className="btn btn-brand btn-block margin-0 margin-t5"
                                                                                                        style={{ maxWidth: '70%' }}
                                                                                                        onClick={() => AddCalendar(m.ID)}
                                                                                                    >
                                                                                                        Update
                                                                                                    </button>
                                                                                                    <button
                                                                                                        style={{ maxWidth: '30%' }}
                                                                                                        className="btn btn-secondary btn-block margin-0 margin-t5 padding-0"
                                                                                                        onClick={() => {
                                                                                                            setCalendarDetails({ SportID: 0 });
                                                                                                            setEditTime(false);
                                                                                                        }}
                                                                                                    >
                                                                                                        <i
                                                                                                            className="la la-times"
                                                                                                            style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                                                        />
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (

                                                                                                <button className="btn btn-brand margin-t10 margin-l0" id='add-calendar-btn' onClick={() => AddCalendar(m.ID)} >
                                                                                                    <i className="la la-plus"></i>
                                                                                                    Add
                                                                                                </button>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {calendarLoading ? (<Loading/>) : (
                                                                                    calendar.length > 0 ? (
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12">
                                                                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                                                                    <table className="kt-datatable__table">
                                                                                                        <thead
                                                                                                            className="kt-datatable__head"
                                                                                                            style={{ display: 'block' }}>
                                                                                                             <tr className="kt-datatable__row block-row">
                                                                                                                <th className="kt-datatable__cell text-clip" width="35%">
                                                                                                                    <span>Time</span>
                                                                                                                </th>
                                                                                                                <th className="kt-datatable__cell text-clip" width="35%">
                                                                                                                    <span>Sport</span>
                                                                                                                </th>
                                                                                                                <th width="10%"></th>
                                                                                                                <th className="kt-datatable__cell text-clip text-right" width="20%">
                                                                                                                    <span className="margin-r20">Action</span>
                                                                                                                </th>
                                                                                                             </tr>
                                                                                                        </thead>
                                                                                                    </table>
                                                                                                </div>
                                                                                                <div 
                                                                                                    className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                                                                    <table className="kt-datatable__table table-striped">
                                                                                                        <tbody
                                                                                                            className="kt-datatable__body">
                                                                                                            {calendar.map((p, i) => (
                                                                                                                <tr
                                                                                                                    key={i}
                                                                                                                    className="kt-datatable__row block-row clickable">

                                                                                                                    <td className="kt-datatable__cell text-clip" width="35%">
                                                                                                                        <span>{p.StartTime} - {p.EndTime}</span>
                                                                                                                    </td>
                                                                                                                    <td className="kt-datatable__cell text-clip" width="35%">
                                                                                                                        <span>{p.SportName}</span>
                                                                                                                    </td>
                                                                                                                    <td className='kt-datatable__cell text-clip' width="10%">
                                                                                                                        <span className="kt-switch kt-switch--icon">
                                                                                                                            <label>
                                                                                                                                <input 
                                                                                                                                    id="chkActive" 
                                                                                                                                    name="chkActive" 
                                                                                                                                    type="checkbox"
                                                                                                                                    defaultChecked={p.Active && p.Active == 'N' ? '' : 'checked'}
                                                                                                                                    onChange={(event) => updateActive(p.ID, event.target.checked ? 'Y' : 'N')} 
                                                                                                                                />
                                                                                                                                <span></span>
                                                                                                                            </label>
                                                                                                                        </span>
                                                                                                                    </td>
                                                                                                                    <td className="kt-datatable__cell text-clip text-right" width="20%">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                                                        
                                                                                                                            onClick={() => editCalendarHandler(p)}>  
                                                                                                                            <i className="flaticon2-edit"></i>
                                                                                                                        </button>
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            onClick={() => removeCalendarHandler(p.ID, p.DayID)}
                                                                                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                                                            
                                                                                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                                                        </button>
                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                            ))}
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            )}
                                                        </div>
                                                    </form>
                                                </>
                                            )}
                                            {navState == 'Rate' && (
                                                <>
                                                    <div className="accordion accordion-toggle-arrow" id="accordionExample4">
                                                        {(activeSportsTagged && activeSportsTagged.length > 0) && (
                                                            activeSportsTagged.map((m, i) => (
                                                                <div className="card" key={i}>
                                                                    <div className="card-header" id={`menuHeading-${m.ID}`}
                                                                        onClick={() => fetchCoachBookingRate(selectedCoach.ID, m.SportID)}
                                                                    >
                                                                        <div className="card-title collapsed" id={i}  data-toggle="collapse" data-target={`#${helper.SanitizeUrl(m.Name)}`} onClick={cardRateClick} aria-expanded="false" aria-controls={helper.SanitizeUrl(m.Name)}>
                                                                            <span> 
                                                                                {m.Logo && m.Logo != '' ? 
                                                                                    <img width="40px" height="40px" src={m.Logo} alt="user-profile" /> : 
                                                                                    <div className={`kt-badge kt-badge--xl kt-badge--info`}> {m.Name.substring(0, 1)}</div>
                                                                                }
                                                                            </span>
                                                                            <div className="container-calender">
                                                                                <span className="date-text">
                                                                                    {m.Name}
                                                                                </span> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id={helper.SanitizeUrl(m.Name)} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4" style={{}}>
                                                                        <div className="card-body">
                                                                            <div className="kt-widget4">
                                                                                <div className="kt-widget4__item padding-tb5">
                                                                                    <table id="tblGreenFee" className="table table-bordered" style={{ width: '100%', backgroundColor: 'none' }}>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: '5%' }}>No</th>
                                                                                                <th style={{ width: '15%' }}>Particular</th>
                                                                                                <th style={{ width: '15%' }}>Type</th>
                                                                                                <th style={{ width: '10%' }} className='column_rate_center'>Rate</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {(rateDetails && rateDetails.length > 0) && rateDetails.map((detail, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td>{index + 1}</td>
                                                                                                    <td>{detail.UserType}</td>
                                                                                                    <td>{detail.Type}</td>
                                                                                                    <td>
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            className="form-control input-sm"
                                                                                                            value={detail.Rate || 0}
                                                                                                            onChange={(e) => updateRateDetails(detail.UserType, detail.Type, 'Rate', e.target.value)}

                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="clearfix form-actions">
                                                                                    <button type="button" id="btnSave" name="btnSave" className="btn btn-primary" onClick={saveRateDetails}>
                                                                                        <i className="fa fa-save"></i> Save
                                                                                    </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    
                                </RightSideDetails>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Calender;