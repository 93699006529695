import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getCoachMasterAllUrl                                      = '/api/admin/coach/getCoachMasterAll';
const getSportTaggingByCoachIDUrl                               = (coachID) => `/api/admin/coach/getSportTaggingByCoachID/${coachID}`;
const getActiveSportTaggingByCoachIDUrl                         = (coachID) => `/api/admin/coach/getActiveSportTaggingByCoachID/${coachID}`;
const getSportBookByCoachIDUrl                                  = (coachID) => `/api/admin/coach/getSportBookByCoachID/${coachID}`;
const getCoachBySportIDUrl                                      = (sportID) => `/api/admin/coach/getCoachBySportID/${sportID}`;
const postCoachUrl                                              = '/api/admin/coach/postCoach';
const updateCoachAdvanceBookingUrl                              = '/api/admin/coach/updateCoachAdvanceBooking';
const getDaysUrl                                                = '/api/admin/coach/getDays';
const postCalendarUrl                                           = '/api/admin/coach/postCalendar';
const getCalendarByCoachIDUrl                                   = (coachID, dayID) => `/api/admin/coach/getCalendarByCoachID/${coachID}/${dayID}`;
const getCoachBookingRateUrl                                    = (coachID, sportID) => `/api/admin/coach/getCoachBookingRate/${coachID}/${sportID}`;
const getCoachAdvanceBookingByCoachIDUrl                        = (coachID) => `/api/admin/coach/getCoachAdvanceBookingByCoachID/${coachID}`;
const deleteCalendarUrl                                         = (id) => `/api/admin/coach/deleteCalendar/${id}`;
const updateCalendarActiveUrl                                   = (id, active) => `/api/admin/coach/updateCalendarActive/${id}/${active}`;
const postCoachBookingRateUrl                                   = '/api/admin/coach/postCoachBookingRate';
const getCoachBookUrl                                           = '/api/admin/coach/getCoachBook';
const postCoachTimeBlockUrl                                     = '/api/admin/coach/postCoachTimeBlock';  
const deleteCoachTimeBlockUrl                                   = (id) => `/api/admin/coach/deleteCoachTimeBlock/${id}`;  
const postCoachBookUrl                                          = `/api/admin/coach/postCoachBook/`;  
const getCoachPaymentTaggingByCoachBookIDUrl                    = (coachBookID) => `/api/admin/coach/getCoachPaymentTaggingByCoachBookID/${coachBookID}`;
const getCoachCancellationSheetUrl                              = (fDate, tDate, coachID, sportID) => `/api/admin/coach/getCoachCancellationSheet/${fDate}/${tDate}/${coachID}/${sportID}`;
const getCoachBookingReportUrl                                  = (fDate, tDate, coachID, sportID) => `/api/admin/coach/getCoachBookingReport/${fDate}/${tDate}/${coachID}/${sportID}`;
const deleteCoachBookUrl                                        = (coachBookID) => `/api/admin/coach/deleteCoachBook/${coachBookID}`;  
const updateSportTaggedActiveUrl                                = (id, active) => `/api/admin/coach/updateSportTaggedActive/${id}/${active}`;

export const getCoachMasterAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCoachMasterAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getDays = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getDaysUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSportTaggingByCoachID = async (coachID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getSportTaggingByCoachIDUrl(coachID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getActiveSportTaggingByCoachID = async (coachID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getActiveSportTaggingByCoachIDUrl(coachID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateSportTaggedActive = async (id, active) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        updateSportTaggedActiveUrl(id, active), {},
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSportBookByCoachID = async (coachID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getSportBookByCoachIDUrl(coachID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCoachBySportID = async (sportID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCoachBySportIDUrl(sportID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCoach = async (coachDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCoachUrl,
        { coachDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateCoachAdvanceBooking = async (advanceBooking) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        updateCoachAdvanceBookingUrl,
        { advanceBooking },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCalendar = async (calendarDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCalendarUrl,
        { calendarDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCalendarByCoachID = async (caochID, dayID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCalendarByCoachIDUrl(caochID, dayID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCoachBookingRate = async (caochID, sportID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCoachBookingRateUrl(caochID, sportID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCoachAdvanceBookingByCoachID = async (caochID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCoachAdvanceBookingByCoachIDUrl(caochID),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const updateCalendarActive = async (id, active) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        updateCalendarActiveUrl(id, active), {},
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteCalendar = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteCalendarUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCoachBookingRate = async (rateDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postCoachBookingRateUrl,
        { rateDetails },
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCoachBook = async (coachBookDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( getCoachBookUrl, {coachBookDetails}, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const postCoachTimeBlock = async(books) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postCoachTimeBlockUrl, { books }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const deleteCoachTimeBlock = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosDelete(
        deleteCoachTimeBlockUrl(id),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postCoachBook = async(bookingDetails, paymentTagging)=>{
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost( postCoachBookUrl, { bookingDetails, paymentTagging }, { headers: { auth_token: `bearer ${token}` } } );
    return response;
}

export const getCoachPaymentTaggingByCoachBookID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getCoachPaymentTaggingByCoachBookIDUrl(id),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCoachCancellationSheet = async (fDate, tDate, coachID, sportID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getCoachCancellationSheetUrl(fDate, tDate, coachID, sportID),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCoachBookingReport = async (fDate, tDate, coachID, sportID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getCoachBookingReportUrl(fDate, tDate, coachID, sportID),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const deleteCoachBook = async (coachBookID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        deleteCoachBookUrl(coachBookID),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
